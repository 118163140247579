import {useChartOrderStore} from "@/orderbuild.js";
import {unique} from "@/misc.js";


// Sunday January 4th, 2009 just before Bitcoin Genesis
const OHLC_START = 1231027200


export function ohlcStart(timestamp, periodSeconds=null) {
    if (periodSeconds===null)
        periodSeconds = useChartOrderStore().intervalSecs
    return Math.floor((timestamp-OHLC_START) / periodSeconds) * periodSeconds + OHLC_START
}

export function nearestOhlcStart(timestamp, periodSeconds=null) {
    if (periodSeconds===null)
        periodSeconds = useChartOrderStore().intervalSecs
    return Math.round((timestamp-OHLC_START) / periodSeconds) * periodSeconds + OHLC_START
}

export function pointsToTvOhlcStart(points, periodSeconds=null) {
    return points === null ? null : points.map((p) => {
        return {time: nearestOhlcStart(p.time, periodSeconds), price: p.price}
    })
}

export function dirtyPoints(pointsA, pointsB) {
    if (pointsA === undefined)
        return true
    if (pointsB === undefined)
        return false
    if (pointsB === null)
        return pointsA !== null
    if (pointsA === null)
        return pointsB.length > 0
    if (pointsA.length !== pointsB.length)
        return true
    for (const i in pointsA) {
        const a = pointsA[i]
        const b = pointsB[i]
        if (a === null && b !== null || a !== null && b === null ||
            a.time !== b.time || a.price !== b.price)
            return true
    }
    return false
} // B is modifying A
function dirtyKeys(propsA, propsB) {
    if (propsB === null)
        return propsA === null ? [] : [...Object.keys(propsA)]
    if (propsA === null)
        return [...Object.keys(propsB)]
    const keys = unique([...Object.keys(propsA), ...Object.keys(propsB)])
    return keys.filter((k) => propsB[k] !== undefined && (!(k in propsA) || propsA[k] !== propsB[k]))
}

export function dirtyItems(a, b) {
    const result = {}
    for (const k of dirtyKeys(a, b))
        result[k] = b[k]
    return result
}