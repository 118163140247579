import {ethers} from "ethers";
import {AbiURLCache} from "../common.js";
import {provider as walletProvider} from "@/blockchain/wallet.js";

export const abiCache = new AbiURLCache('/contract/out/')


export function vaultAddress( factory, vaultInitCodeHash, owner, num=0) {
    if( !owner || !factory || !vaultInitCodeHash )
        return null
    const salt = ethers.solidityPackedKeccak256(['address','uint8'],[owner,num])
    return ethers.getCreate2Address(factory, salt, vaultInitCodeHash)
}


export function contractOrNull(addr,abi,provider) {
    try {
        return new ethers.Contract(addr,abi,provider)
    }
    catch (e) {
        return null
    }
}

export async function queryHelperContract(helper, provider) {
    return newContract(helper, 'QueryHelper', provider)
}


// do not supply extensions with name or file: e.g.
// use newContract(addr, 'IVaultImpl', provider, 'IVault') to get the ABI from IVault.sol/IVaultImpl.json
export async function newContract(addr, name, provider) {
    const abi = await abiCache.get(name)
    console.log(`${name} ABI`, abi)
    return new ethers.Contract(addr, abi, provider)
}


export async function erc20Contract(addr, provider) {
    return newContract(addr, 'IERC20Metadata', provider)
}


export async function vaultContract(addr, provider=null) {
    if (provider===null)
        provider = walletProvider
    return await newContract(addr, 'IVault', provider)
}
